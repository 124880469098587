import Cookies from "js-cookie";

const YH_LIST_KEY = 'RECENT_YH_LIST';

export function getRecentYhList() {
  let list = [];
  try {
    const json = Cookies.get(YH_LIST_KEY);
    if (json) {
      list = JSON.parse(json);
    }
  } catch (err) {}
  
  console.log(list);
  return list;
}

export function addToRecentYhList(data) {
  let items = getRecentYhList();
  let index = items.findIndex(item => item.yhbh == data.yhbh);
  let item = {
    jgbm: data.jgbm,
    yhbh: data.yhbh,
    yhmc: data.yhmc,
    recentUseTime: Date.now()
  };
  if (index == -1) {
    items.unshift(item);
  } else {
    items.splice(index, 1, item);
  }
  items.sort((a,b) => b.recentUseTime - a.recentUseTime);
  Cookies.set(YH_LIST_KEY, items, {expires: 365});
}
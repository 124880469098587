<template>
  <page-view :title="pageTitle" left-arrow>
    <van-form :show-error="true" :show-error-message="false" :scroll-to-error="true" :validate-first="true"
      @submit="onSubmit" @failed="onFailed">
      <van-cell-group class="mt-10 van-cell-group--inset">
        <van-field required v-model="form.yhbh" name="yhbh" label="户号" label-width="2.5em" placeholder="请输入户号"
          :rules="[{ required: true, message: '请输入户号' }]" />
        <van-cell :style="{backgroundColor: 'transparent'}">
          <template #title>
            <p style="font-size: 12px;color: #888;">如何查询户号？</p>
          </template>
          <template #label>
            <ol>
              <li>1、如果您办理了短信通知业务，可以在短信内容里找到;</li>
              <li>2、之前的电费账单里面可以找到；</li>
              <li>3、请到当地营业厅或者拨打营业厅电话查询。</li>
            </ol>
          </template>
        </van-cell>
      </van-cell-group>

      <div style="margin: 16px;">
        <van-button block type="primary" native-type="submit">下一步</van-button>
      </div>
    </van-form>

    <van-cell-group v-if="recentYhList.length" title="最近缴费" class="van-cell-group--inset">
      <van-cell 
        v-for="item in recentYhList" 
        :key="item.yhbh" 
        :title="item.yhmc" 
        :label="item.yhbh" 
        is-link 
        @click="handleCheckYhbh(item.yhbh)"
      />
    </van-cell-group>
  </page-view>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { getRecentYhList, addToRecentYhList} from '@/utils/recent-yh-list';

  import { yhJbxx_selectiveGetOne } from '@/api/zhcxschema/YhJbxxAPI';


  export default {
    name: 'ksjf',
    data() {
      return {
        pageTitle: '快捷缴费',
        form: {
          yhbh: ''
        },
      }
    },
    computed: {
      ...mapGetters(['jgbm']),
      recentYhList() {
        return getRecentYhList();
      }
    },
    methods: {
      onFailed({values, errors}) {
        console.log('failed:, Errors:', errors)
        this.$notify({
          type: 'danger',
          message: errors[0].message
        });
      },
      onSubmit(values) {
        console.log("Submit:", values);
        if (values.yhbh) {
          this.handleCheckYhbh(values.yhbh);
        }
      },
      // 检查用户编号是否有效
      handleCheckYhbh(yhbh) {
        this.$toast.loading({
          message: '加载中...',
          duration: 0,
          forbidClick: true,
        });

        yhJbxx_selectiveGetOne({yhbh}).then(res => {
          console.log(res);
          this.$toast.clear();
          if (res.content) {
            addToRecentYhList({
              jgbm: res.content.jgbm,
              yhbh: res.content.yhbh,
              yhmc: res.content.yhmc
            });
            this.$router.push({name: 'Dfcj', params:{yhbh,type: -1}});
          } else {
            throw new Error('未查询到用户')
          }
        }).catch(err => {
          this.$toast.clear();
          this.$toast(err.message);
        });
      }
    },
  }
</script>

<style lang="scss" scoped>
  .van-cell-group--inset {
    margin: 0 16px;
    border-radius: 8px;
    overflow: hidden;
  }
</style>